<template>
	<v-container class="mt-4 ml-2" fluid v-if="planteles.includes(21) || planteles.includes(13)">
	  <v-row >
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Mis Pedidos</span>
	  		    <v-spacer></v-spacer>

            <v-btn
              color="orange"
              dark
              outlined
              class="mr-2"
              @click="dialog = true"
              tile
            >
              Realizar pedido
              <v-img src="@/assets/iconos/comida.png" max-width="32"></v-img>
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              tile
            >
              <v-icon small>mdi-refresh</v-icon>
            </v-btn>

	  		  </v-card-title>
	  		  <v-card-text>
	  		  	<v-row justify="end">
              <v-col cols="12" md="3" lg="2" class="pb-0">
	  		  			<v-text-field label="Fecha Inicial" 
                  type="date" 
                  filled 
                  dense 
                  hide-details 
                  v-model="filtroFechaIni"
                ></v-text-field>
	  		  		</v-col>
              <v-col cols="12" md="3" lg="2" class="pb-0">
	  		  			<v-text-field label="Fecha Final" 
                  type="date" 
                  filled 
                  dense 
                  hide-details 
                  v-model="filtroFechaFin"
                ></v-text-field>
	  		  		</v-col>
              <v-spacer></v-spacer>
	  		  	</v-row>

            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="12" md="3" class="pt-0">
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>

	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="pedidos"
							    class="elevation-0"
							    :search="search"
							    :mobile-breakpoint="100"
			            dense
                  item-class="clase_row"
							  >

                  <template v-slot:item.estatus="{ item }">
                    <v-chip color="orange" v-if="item.estatus == 1" small dark >Aceptado</v-chip>
                    <v-chip color="red"    v-if="item.estatus == 2" small dark >Rechazado</v-chip>
                    <v-chip color="green"  v-if="item.estatus == 3" small dark >Completado</v-chip>
                    <v-chip color="grey"   v-if="item.estatus == 0" small dark >Pendiente</v-chip>
                  </template>

							    <template v-slot:item.actions="{ item }">
							    	<v-btn 
                      tile
							    		color="primary" 
							    		x-small
							    		@click="editItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small>mdi-magnify</v-icon>
							    	</v-btn>

							    	<v-btn 
                      tile
							    		color="error" 
							    		x-small
                      v-if="item.estatus == 0"
							    		@click="deleteItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small> mdi-delete</v-icon>
							    	</v-btn>
							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small

							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="600px"
      persistent
    >
      <v-card>
        <v-card-title>
        	Realiza tu pedido
        	<v-img class="ml-2" src="@/assets/iconos/cesta.png" max-width="28"/>
        </v-card-title>

        <v-card-text>
          <v-row>

            <v-col cols="12" v-if="editedIndex >= 0">
              <div>Cliente: {{ getdatosUsuario.nombre_completo }}</div>
              <div>Total: {{ editedItem.total }}</div>
              <div>Fecha: {{ editedItem.fecha_creacion }}</div>
              <div>
                <v-chip color="orange" v-if="editedItem.estatus == 1" small dark >Aceptado</v-chip>
                <v-chip color="red"    v-if="editedItem.estatus == 2" small dark >Rechazado</v-chip>
                <v-chip color="green"  v-if="editedItem.estatus == 3" small dark >Completado</v-chip>
                <v-chip color="grey"   v-if="editedItem.estatus == 0" small dark >Pendiente</v-chip>
              </div>
            </v-col>

          	<v-col cols="12" v-for="(comida, i) in comidas" :key="i" v-if="editedIndex < 0">
          		<v-card class="shadowCard">
		            <div class="d-flex flex-no-wrap justify-space-between">
		              <div>
		                <v-card-title
		                  class="text-h5"
		                  v-text="comida.comida"
		                ></v-card-title>

		                <v-card-subtitle v-text="comida.descripcion"></v-card-subtitle>

		                <div class="pl-4 text-subtitle-1">
							        <b>${{ comida.costo }}</b>
							      </div>

		                <v-card-actions class="pl-3">
		                  <v-checkbox
				                v-model="comida.seleccionado"
				                label="Lo quiero"
				                class="pa-0"
				              ></v-checkbox>
				            </v-card-actions>
		              </div>

		              <v-avatar
		                class="ma-3"
		                size="125"
		                tile
		              >
		                <v-img :src="url + comida.foto"></v-img>
		              </v-avatar>
		            </div>
		          </v-card>
          	</v-col>

            <v-col cols="12" v-for="(co, j) in editedItem.articulos" :key="j" v-if="editedIndex >= 0">
              <v-card class="shadowCard">
                <div class="d-flex flex-no-wrap justify-space-between">
                  <div>
                    <v-card-title
                      class="text-h5"
                      v-text="co.comida"
                    ></v-card-title>

                    <v-card-subtitle v-text="co.descripcion"></v-card-subtitle>

                    <div class="pl-4 text-subtitle-1">
                      <b>${{ co.costo }}</b>
                    </div>
                  </div>

                  <v-avatar
                    class="ma-3"
                    size="125"
                    tile
                  >
                    <v-img :src="url + co.foto"></v-img>
                  </v-avatar>
                </div>
              </v-card>
            </v-col>
            
          </v-row>
        </v-card-text>

        <v-btn
          tile
          small
          @click="close"
          absolute
          right
          top
          icon
        >
          <v-icon small>mdi-close</v-icon>
        </v-btn>

        <v-card-actions v-if="editedItem.estatus == 0">
          <v-btn
            color="orange darken-1"
            class=""
            large
            outlined
            block
            tile
            dark
            small
            @click="comidas.filter( el => el.seleccionado ).length ? save(): ''"
          >
            <!-- @click="comidas.filter( el => el.seleccionado ).length ? dialogPago = true: ''" -->
            Realizar pago de: ${{ pagoTotal }}
            <v-img src="@/assets/iconos/pagar.png" max-width="32" class="ml-4"></v-img>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


	  <!-- Dialogo para ver y agregar el detalle -->
	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialogPago"
      max-width="400px"
      persistent
    >
      <v-card>
        <v-card-title>
        	<span class="text-subtitle-1">Realizar pago de: ${{ pagoTotal }}</b></span>
          <v-img src="@/assets/iconos/pagar.png" max-width="32" class="ml-4"></v-img>
        </v-card-title>

        <v-card-text>

          Hola, para realizar el pago, se requiere realizar el depósito o transferencia a la siguiente cuenta.
          <br/>
          <br/>
          Tarjeta: 4741 7436 6181 0722
          <br/>
          Asunto: INBI FOOD
          <br/>
          <br/>
          Cuando hayas realizado el pago, favor de subir el comprobante.
          <br/>
          <br/>
          <v-row>
            <v-card-text align="center" class="pa-0">
              <div v-if="!file">
                <div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true" @dragleave="dragging = false">
                  <div class="dropZone-info" @drag="onChange">
                    <span class="fa fa-cloud-upload dropZone-title"></span>
                    <span class="dropZone-title blue--text"><b>Arrastre el archivo para cargar</b></span>
                    <div class="dropZone-upload-limit-info">
                      <div>Extensión: png, jpg, jpeg, svg</div>
                      <div>Tamaño máximo: 10 MB</div>
                    </div>
                  </div>
                  <input type="file" @change="onChange">
                </div>
              </div>

              <v-btn  v-else color="red" class="mb-2" dense @click="removeFile" fab small dark><v-icon>mdi-delete</v-icon></v-btn>
              <v-img :src="vistaPrevia.url" v-if="vistaPrevia" contain max-height="300"/>
            </v-card-text>
            
          </v-row>
        </v-card-text>

        <v-btn
          tile
          small
          @click="dialogPago = false"
          absolute
          right
          top
          icon
        >
          <v-icon small>mdi-close</v-icon>
        </v-btn>

        <v-card-actions>
          <v-btn
            color="blue darken-1"
            class=""
            large
            outlined
            block
            tile
            dark
            small
            @click="file ? save() : ''"
          >
            Enviar comprobante
            <v-img src="@/assets/iconos/recibo.png" max-width="32" class="ml-4"></v-img>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row justify="space-around">
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar el pedido?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    mixins: [ validarErrores, funcionesExcel ],

  	components:{
      Alerta,
      carga,
    },

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      
      editedIndex: -1,

      id_puesto:0,

      editedItem: {
        idmis_pedidos: 0,
				proyecto: 0,
				alcance: '',
				prioridad: 0,
				contacto_cliente: 0,
				id_cliente: '',
				estatus: 0,
				deleted:0,
				fecha_creacion: null,
      },

      defaultItem: {
        idmis_pedidos: 0,
				proyecto: 0,
				alcance: '',
				prioridad: 0,
				contacto_cliente: 0,
				id_cliente: '',
				estatus: 0,
				deleted:0,
				fecha_creacion: null,
      },

      // Filtros: 
      incio:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			fin  :(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      salones: [],
      headers: [
        { text: 'ID'       , value: 'idmis_pedidos'  },
        { text: 'Detalle'  , value: 'detalle'        },
        { text: 'Cantidad' , value: 'cantidad'       },
        { text: 'Total'    , value: 'total'          },
        { text: 'Estatus'  , value: 'estatus'        },
        { text: 'Actions'  , value: 'actions'        },
      ],

      pedidos: [],
      puestos: [],
      turnos:[],
      planteles:[],
      filtroEstatus: 0,
      filtroFechaIni: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      filtroFechaFin: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),


      detalles:[],
      fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      comidas:[],

      actividad:'',
			realizada:0,
      dialogDetalles:false,

      headersDetalle:[
        { text: 'Actividad'     , value: 'actividad'     },
        { text: 'Fecha Entrega' , value: 'fecha_entrega' },
        { text: 'Realizada'     , value: 'realizada'     },
        { text: 'Actions'       , value: 'actions', sortable: false },
      ],
      proyecto:'',
      cliente:'',


      urlFotos: '',

      dialogPago: false,

      archivos:null,
      vistaPrevia: null,
      file: '',
      dragging: false,
      dialgImagen:false,
      url_servidor: '',
      fullscreen: false,
      pdf:null,
      dialogPDF: false,
      planteles: []
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar proyecto' : 'Editar proyecto'
      },

      pagoTotal( ){

      	return this.comidas.filter( el => el.seleccionado ).map(item => item.costo).reduce((prev, curr) => prev + curr, 0)

      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

    },

    async created () {

      this.planteles = this.getdatosUsuario.planteles.map((registro)=>{ return registro.idplantel })

      console.log( this.planteles )

    	this.url = axios.defaults.baseURL + "fotos_comidas/";

      await this.initialize()
      await this.getComidas()
    },

    methods: {
      initialize () {
      	this.cargar = true
        this.pedidos = []

        let payload = {
          filtroFechaIni: this.filtroFechaIni,
          filtroFechaFin: this.filtroFechaFin,
          id_usuario    : this.getdatosUsuario.iderp
        }

        return this.$http.post('compras.list' , payload ).then(response=>{
        	this.pedidos = response.data

          this.puestos = this.pedidos.map((registo) => { return { idpuesto: registo.id_puesto, puesto: registo.puesto } })
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getComidas () {
        this.comidas = []
        this.$http.get('comidas.list').then(response=>{
          this.cargar = false

          this.comidas = response.data 
          // Traer los puestos
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.pedidos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.pedidos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
      	this.cargar = true
        // Lo mandapos por el EP
        this.editedItem.deleted = 1

        this.$http.put('compras.elimninar/' + this.editedItem.idmis_pedidos, this.editedItem).then(response=>{
        	this.cargar = true
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async save () {
      	// Cargamos al usuario que hizo el cambio
	      this.cargar = true

        let comprobante_pago = ''
        try{
          // comprobante_pago = await this.grabarImagen( )
        }catch( error ){
          return  this.validarError( error.response.data.message )
        }


        if (this.editedIndex > -1) {
	        // Lo mandapos por el EP
          this.$http.put('seguimiento.pedidos.update/' + this.editedItem.idmis_pedidos, this.editedItem).then(response=>{
          	this.validarSuccess( response.data.message )
          	this.initialize()
        		this.close()
	        }).catch( error =>{
	          this.validarError( error.response.data.message )
	        }).finally( () => { this.cargar = false })

        } else {

        	let payload = {
        		id_usuario       : this.getdatosUsuario.iderp,
        		estatus          : 0,
        		total            : this.pagoTotal,
        		comidas          : this.comidas.filter( el => el.seleccionado ),
            comprobante_pago
        	}

	        // Lo mandapos por el EP
          this.$http.post('compra.add', payload).then(response=>{
            this.dialogPago = false
          	this.initialize()
        		this.close()
	        	this.cargar = false
	        }).catch( error =>{
          	this.validarError( error.response.data.message )
        	}).finally( () => { this.cargar = false })
        }
      },

      grabarImagen() {

        let formData = new FormData();
        formData.append("file", this.file);

        return axios.post("compras.comprobante", formData).then((response) => {
          this.vistaPrevia = null;
          this.file        = null;
          return Promise.resolve(response.data.nombre);
        }).catch((error) => {
          return Promise.reject(error);
        }).finally(() => {
          this.cargar = false;
        });
      },

      getBase64(file) {
        var me = this
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {

          let extension = file.name.split('.')

          me.vistaPrevia = {
            url: reader.result, 
            image_name: file.name,
            extension: extension[extension.length - 1]
          }
        };
      },
   
      onChange(e) {
        var files = e.target.files || e.dataTransfer.files;

        if (!files.length) {
          this.dragging = false;
          return;
        }
        
        this.createFile(files[0]);
      },

      createFile(file) {
        if (file.size > 40000000) {
          alert('please check file size no over 40 MB.')
          this.dragging = false;
          return;
        }
        
        let extension = file.name.split('.')
        
        this.file = file;
        this.extension = extension[extension.length - 1]
        this.getBase64(this.file)
        this.dragging = false;
      },

      removeFile() {
        this.vistaPrevia        = null
        this.archivos           = null
        this.file               = '';
      },

    },
  }
</script>

<style scoped>
  .dropZone {
    width: 100%;
    height: 150px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px solid #D8D8D8;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  ..dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #D8D8D8;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 80%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #D8D8D8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .removeFile {
    width: 200px;
  }

  /* Estilos del contenedor de cuadros */
  .cuadros-container {
    display: flex;
    justify-content: space-between; /* Distribuye los cuadros equitativamente */
    align-items: center; /* Alinea verticalmente los cuadros */
    flex-wrap: nowrap; /* Evita que los cuadros se envuelvan a la siguiente línea */
    padding: 10px;
  }

  /* Estilos de los cuadros */
  .cuadro {
    width: 18%; /* Ajusta el ancho de los cuadros según el espacio disponible */
    padding: 10px;
    border: 1px solid #ccc;
    text-align: center;
  }

  .texto_contenedor{
    justify-content: center; /* Centrado horizontal */
    align-items: center; /* Centrado vertical */
    max-width: 100%;
    padding-left: 12%;
    padding-right: 12%;
  }
</style>